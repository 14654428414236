// src/components/ImagineThis/ProductCard.jsx
import styled from "styled-components/macro";
import PgCard from "src/components/Card/PgCard";

const IMAGE_CDN_BASE_URL = process.env.REACT_APP_PGFE_IMG_ASSETS_PATH;

const ProductCard = ({ item }) => {
  // const overlayImage = `${IMAGE_CDN_BASE_URL}ers/imagine-this-2025-prize-counter/x_1.png`; // Overlay PNG
  const overlayImage = `${IMAGE_CDN_BASE_URL}ers/imagine-this-2025-prize-counter/red-X.png`; // Overlay PNG

  return (
    <CardWrapper>
      <PgCard
        image={item.imagepath}
        imageAlt={item.nameen}
        title={item.namefr}
        subtitle={item.nameen}
        highlightColor={item.color}
        highlightTextColor="#fff"
        variant="portrait"
        className={`item-card ${ item.count === 0 ? "sold-out" : ""}`}
        style={{ color: item.nameen ? "#fff" : "#000" }}
      />
      {item.count === 0 && <Overlay src={overlayImage} alt="Sold Out Overlay" />}
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  position: relative;
  display: inline-block;
  height: 100%;
  .card-text {
    width: 100%;
    p {
      margin: 0;
    }
  }
  .card-body {
    padding: 10px;
  }
  .card-title {
    h3 {
      font-size: 16px;
    }
  }
  .item-card {
    margin-bottom: 20px;
    background-color: white;
    &.sold-out {
      .card-image-top {
        opacity: 0.5;
        filter : grayscale(80%);
      }
      .card-header {
        opacity: 0.5;
      }
      h3, h4 {
      }
    }
    .card-image-top {
      aspect-ratio: 9 / 9;
      img {
        object-fit: contain;
      }
    }
    .card-text-wrapper {
      .card-header {
        border-bottom: none;
      }
      &.portrait {
        top: unset !important;
        bottom: 0 !important;
        width: 100%;
      }
      .card-body {
        .cart-text {
          width: 100%;
          p {
            margin: 0;
            font-size: 14px;
          }
        }
      }
    }
  }
`;

const Overlay = styled.img`
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  object-fit: contain;
  pointer-events: none;
`;

export default ProductCard;
