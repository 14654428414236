import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import EventFinished from "src/components/EventFinished";
import TitleSection from "src/components/TitleSection/TitleSection";
import { Col, Row } from "react-bootstrap";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import Hero from "src/components/Hero/Hero";
import ReactMarkdown from "react-markdown";

const BigGameBash2025 = () => {
  const { t } = useTranslation();
  // Function to transform JSON data into an array of items
  const transformMenuData = (data) => {
    const items = [];
    Object.keys(data).forEach((categoryKey) => {
      const category = data[categoryKey];
      Object.keys(category).forEach((itemKey) => {
        if (itemKey !== "title") {
          items.push({
            category: category.title,
            title: category[itemKey].title,
            text: category[itemKey].text,
          });
        }
      });
    });
    return items;
  };
  const items = transformMenuData(
    t("bigGameBash2025.content.menu", { returnObjects: true })
  );
  return (
    <MainDiv>
      <Hero
        bgImage={
          "entertainment/variety/big-game-bash-2025/superbowl-LIX-hero-image-2400x800px.jpg"
        }
        image={t("bigGameBash2025.hero.image")}
        imageAlt={t("bigGameBash2025.hero.title")}
      />
      <EventFinished
        scheduleKeyName="bigGameBash2025"
        eventType="entertainment"
      />
      <container-div>
        <TitleSection
          title={t("bigGameBash2025.content.header.title")}
          text={t("bigGameBash2025.content.header.text")}
          borderColor="#651B38"
          dark={false}
        />
        <Row
          xs={1}
          sm={2}
          className="p-2 m-0 lightblack d-flex justify-content-center align-items-center"
        >
          <Col className="m-0 p-2">
            <ResponsiveImage
              src={t("bigGameBash2025.content.eventDetails.image")}
              alt={t("bigGameBash2025.content.header.title")}
            />
          </Col>
          <Col className="m-0 p-2">
            <Row className="m-2">
              <SubTitleSection
                subTitle={t("bigGameBash2025.content.eventDetails.title")}
                text={t("bigGameBash2025.content.eventDetails.text")}
                borderColor="#651B38"
              />
            </Row>
          </Col>
        </Row>
        <Row xs={1} sm={2} className="p-2 m-0 mt-4">
          <ResponsiveImage
            src={
              "entertainment/variety/big-game-bash-2025/superbowl-LIX-2400x1600px-food-special.jpg"
            }
            alt={t("bigGameBash2025.content.menu.title")}
            className="food-image"
          />
          <TitleSection
            title={t("bigGameBash2025.content.menu.title")}
            borderColor="#651B38"
          />
          <Col className="m-0 p-0" xs={12} sm={6}>
            <Row xs={1} sm={1} className="mt-4 m-2">
              {Object.values(
                t("bigGameBash2025.content.menu.snacks", {
                  returnObjects: true,
                })
              )?.map(
                (item, index) =>
                  index > 0 && (
                    <GridItem key={item?.title}>
                      <div className="item-header">
                        <h3>{item?.title}</h3>
                      </div>
                      <ReactMarkdown>{item?.text}</ReactMarkdown>
                    </GridItem>
                  )
              )}
              {Object.values(
                t("bigGameBash2025.content.menu.mains", {
                  returnObjects: true,
                })
              )?.map(
                (item, index) =>
                  index > 0 && (
                    <GridItem key={item?.title}>
                      <div className="item-header">
                        <h3>{item?.title}</h3>
                      </div>
                      <ReactMarkdown>{item?.text}</ReactMarkdown>
                    </GridItem>
                  )
              )}
            </Row>
          </Col>
          <Col className="m-0 p-0" xs={12} sm={6}>
            <Row
              xs={1}
              sm={1}
              className="mt-4 m-2 d-flex justify-content-center align-items-center"
            >
              <Row xs={1} sm={1} className="m-0 mb-4 p-0">
                <ResponsiveImage
                  src={
                    "entertainment/variety/big-game-bash-2025/superbowl-LIX-2400x1600px-drink-special.jpg"
                  }
                  alt={t("bigGameBash2025.content.menu.drinks.heineken.title")}
                />
              </Row>
              <GridItem>
                <div className="item-header">
                  <h3>
                    {t("bigGameBash2025.content.menu.drinks.heineken.title")}
                  </h3>
                </div>
                <ReactMarkdown>
                  {t("bigGameBash2025.content.menu.drinks.heineken.text")}
                </ReactMarkdown>
              </GridItem>
            </Row>
          </Col>
        </Row>
        <Row xs={1} sm={1} className="p-2 m-0 mt-4">
          <ReactMarkdown className="text-center">
            {t("bigGameBash2025.content.bottomText")}
          </ReactMarkdown>
        </Row>
      </container-div>
    </MainDiv>
  );
};
const MainDiv = styled.div`
  height: auto;
  width: 100%;
  background: var(--black);
  color: var(--white);
  .lightblack {
    background: var(--lightblack);
  }
  .hero_text_container {
    display: none;
  }
  .hero_image_container {
    padding: 40px;
  }
  .food-image {
    object-fit: cover;
    object-position: bottom;
    max-height: 400px;
  }
  .title-section {
    span {
      margin-bottom: 0;
    }
  }
  .subtitle-section {
    ul {
      margin-top: 20px;
      li {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
  }
`;
// GridItem styled component for each item
const GridItem = styled(Col)`
  break-inside: avoid;

  & > p {
    text-transform: uppercase;
    padding-left: 10px;
    font-size: 14px;
  }
  &.col {
    padding: 0;
  }
  div.item-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    & > h3 {
      margin: 0;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
      border-left: 2px solid #651b38;
      padding-left: 10px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 10px;
    margin-top: 20px;
  }
`;
export default BigGameBash2025;
