import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import InventoryGrid from "src/components/ImagineThis/InventoryGrid";

const ThursdayGiftInventory = () => {

  return (
    <>
      <Div>
        <InventoryGrid tv={ true } />
      </Div>
    </>
  );
};

const Div = styled.div`
  background: var(--black);
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--white);
`;

export default ThursdayGiftInventory;
