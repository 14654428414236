import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MarkdownWithRouter from "../MarkdownWithRouter";

const CashGamesInfo = () => {
  const { t } = useTranslation();
  return (
    <Div>
      <MarkdownWithRouter>{t("cash-games.infoBox")}</MarkdownWithRouter>
    </Div>
  );
};
const Div = styled.div`
  background-color: #333;
  margin: 30px auto;
  text-align: center;
  width: 100%;
  & > p {
    padding: 20px;
    font-size: 16px;
    color: #aaa;
    margin: 0 0 10px;
  }
`;
export default CashGamesInfo;
