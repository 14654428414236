import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";
import TitleSection from "src/components/TitleSection/TitleSection";

const RequestPassword = () => {
  const { i18n } = useTranslation();
  return (
    <MainDiv bgImage={useResponsiveImageUrl("reg/bg-login.png")}>
      <div>
      <container-div>
        <div className="text-center">
          <TitleSection
            title={i18n.language === "en" ? "Request Successful" : "Requête réussie"}
            text= {i18n.language === "en" ? "We’re sad to see you go!" : "Nous sommes désolés de vous voir partir!"}
             />
          <p>
            {i18n.language === "en"
              ? "You have been unsubscribed from the Playground Newsletter."
              : "Vous avez été désinscrit de l’Infolettre Playground."}
          </p>
          <p>
            {i18n.language === "en"
              ? "If you didn’t mean to unsubscribe and would like to continue receiving the Playground Newsletter, just visit your My Account page and click on the “Sign me up” option."
              : "Si vous ne désiriez pas vous désinscrire et souhaitez continuer de recevoir l’Infolettre Playground, visitez votre page Mon Playground et cliquez: Je veux m’inscrire!"}
          </p>
        </div>
      </container-div>
      </div>
    </MainDiv>
  );
};
const MainDiv = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
  & > div {
    height: auto;
    min-height: 800px;
    width: 100%;
    background-image: url(${(props) => props.bgImage});
    padding: 100px 0 20px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    container-div > div {
      padding: 20px;
      background: rgba(0, 0, 0, 0.7);
      color: white;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phones and medium tablet styles */
    & > div:first-child {
      min-height: 80vh;
      padding: 100px 20px 40px 20px;
      & > div {
        & > div {
          padding: 10px;
        }
      }
    }
  }
`;
export default RequestPassword;
