import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const ExistingUser = () => {
  const { i18n } = useTranslation();
  return (
    <MainDiv bgImage={useResponsiveImageUrl("reg/bg-login.png")}>
      <div>
        <div>
          <h3>{i18n.language === "en" ? "Success!" : "Vous avez réussi!"}</h3>
          <div>
            <div>
              <ResponsiveImage
                src="svg/Check.svg"
                alt="check"
              />
              <h4>
                {i18n.language === "en"
                  ? "Welcome back to Playground"
                  : "Re-bienvenue au Playground"}
              </h4>
              <h4>
                {i18n.language === "en"
                  ? "Please read the instructions below"
                  : "Veuillez lire les instructions ci-dessous"}
              </h4>
              <p>
                {i18n.language === "en"
                  ? "Thank you for asking us to look up your Playground Player’s Card Info!"
                  : "Merci d’avoir fait la demande de recouvrement de vos informations!"}
              </p>
              <p>
                {i18n.language === "en"
                  ? "Your request has been sent to Playground staff, who will process your request and find your existing Player’s Card number."
                  : "Votre demande a été envoyée au personnel du Playground, qui la traitera et récupérera votre numéro de Carte de Joueur."}
              </p>
              <p>
                {i18n.language === "en"
                  ? "Once this has been done, staff will contact you with your Player’s Card number and, if you have added a new email address, you will also receive a Welcome email that will invite you to create a password to sign up to the Playground website."
                  : "Une fois ce traitement terminé, le personnel du Playground vous contactera pour vous donner votre Numéro de Joueur, et si vous avez utilisez une nouvelle adresse courriel, vous recevrez également un courriel officiel de bienvenue incluant votre numéro de Carte de Joueur ainsi qu’une invitation à créer un mot de passe pour vous connecter au site Web du Playground."}
              </p>
              <p>
                {i18n.language === "en"
                  ? "You can pick up the physical Player’s Card the next time you visit the Club."
                  : "Vous pourrez prendre possession de votre carte à votre prochaine visite au Club."}
              </p>
            </div>
          </div>
        </div>
      </div>
      
    </MainDiv>
  );
};
const MainDiv = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
  & > div:first-child {
    height: auto;
    min-height: 900px;
    width: 100%;
    background-image: url(${(props) => props.bgImage});
    padding: 100px 0 20px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width: 100%;
      max-width: 570px;
      height: auto;
      & > h3 {
        font-size: 22px;
        font-weight: 700;
        margin: 0px;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--red);
        margin-bottom: 30px;
        color: var(--white);
      }
      & > div {
        background: rgba(0, 0, 0, 0.5);
        padding: 30px;
        text-align: center;
        color: var(--white);
        & > div {
          padding: 20px;
          background: rgba(0, 0, 0, 0.7);
          & > img {
            width: 50px;
            height: 50px;
            object-fit: contain;
            margin: 10px auto;
          }
          & > h4:nth-child(2) {
            font-weight: normal;
            margin: 0;
            padding-bottom: 15px;
            border-bottom: 1px solid var(--rewards_grid);
          }
          & > p {
            & > strong {
              & > a {
                color: var(--red) !important;
                &:hover {
                  border-bottom: 1px solid var(--red);
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phones and medium tablet styles */
    & > div:first-child {
      min-height: 80vh;
      padding: 100px 20px 40px 20px;
      & > div {
        & > div {
          padding: 10px;
        }
      }
    }
  }
`;
export default ExistingUser;
