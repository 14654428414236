import i18n from "src/middleware/i18n";
//change DEV to PROD for prod api for user auth
const BASE_API = process.env.REACT_APP_BASE_URL;
import Cookies from 'js-cookie';

export const email_verification = async (gid) => {
  try {
    const response = await fetch(`${BASE_API}/auth/email_verification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Content-Language": i18n.language,
      },
      body: JSON.stringify({ gid: gid }), // Send the code as part of the request body
    });
    if (response.ok) {
      const data = await response.json();
      const successMessage = i18n.t("emailVerification.success");
      return {
        success: true,
        message: successMessage,
        data: data.data,
        meta: data.meta,
      }; // Email confirmation code sent successfully
    } else {
      const errorData = await response.json();
      const errorMessage = i18n.t(`emailVerification.errors.${errorData.key}`);
      return {
        success: false,
        message: errorMessage,
        status: response.status,
      }; // Email confirmation code request failed
    }
  } catch (error) {
    return {
      success: false,
      message: error.message,
    }; // Error occurred while requesting email confirmation code
  }
};

export const send_verification_email = async (access_token) => {
  try {
    const response = await fetch(`${BASE_API}/auth/send_verification_email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
        "Content-Language": i18n.language,
      },
    });
    if (response.ok) {
      return { success: true };
    } else {
      const errorData = await response.json();
      const errorMessage = i18n.t(`emailVerification.errors.${errorData.key}`);
      return {
        success: false,
        message: errorMessage ?? errorData.message,
      }; // Email verification code request failed
    }
  } catch (error) {
    return {
      success: false,
      message: error.message,
    }; // Error occurred while requesting email verification code
  }
};

export const change_password = async (
  access_token,
  oldPassword,
  newPassword,
  confirmPassword
) => {
  try {
    const response = await fetch(`${BASE_API}/auth/change_password`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify({
        old_password: oldPassword,
        new_password: newPassword,
        new_password_confirmation: confirmPassword,
      }),
    });
    if (response.ok) {
      return { success: true }; // Password changed successfully
    } else {
      return { success: false, message: "Password change failed" }; // Password change failed
    }
  } catch (error) {
    console.error("Error changing password:", error.message);
    return {
      success: false,
      message: "Error occurred while changing password",
    }; // Error occurred while changing password
  }
};

export const forgot_password = async (email) => {
  try {
    const response = await fetch(`${BASE_API}/auth/forgot_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Content-Language": i18n.language,
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    if (response.ok) {
      return { success: true }; // Password reset request sent successfully
    } else {
      return { success: false, message: "Password reset request failed" }; // Password reset request failed
    }
  } catch (error) {
    console.error("Error resetting password:", error.message);
    return {
      success: false,
      message: "Error occurred while resetting password",
    }; // Error occurred while resetting password
  }
};

export const reset_password = async (payload) => {
  let validationMessages = [];
  const passwordValidationResult = validatePassword(payload.new_password);
  if (passwordValidationResult !== true) {
    // If the result is not `true`, it will be an array of failed validation messages.
    validationMessages = [...validationMessages, ...passwordValidationResult];
  }

  if (payload.new_password !== payload.new_password_confirmation) {
    validationMessages.push("passwordMismatch");
  }

  if (validationMessages.length > 0) {
    return {
      success: false,
      messages: validationMessages, // Ensure that this key is consistent with what the client expects.
    };
  }

  const urlParams = new URLSearchParams(window.location.search);
  const gid = urlParams.get("gid");

  if (!gid) {
    console.error("GID (token) is missing from the URL.");
    return {
      success: false,
      messages: ["gidMissing"],
    };
  }

  try {
    const response = await fetch(`${BASE_API}/auth/reset_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Content-Language": i18n.language,
      },
      body: JSON.stringify({
        gid: gid,
        new_password: payload.new_password,
        new_password_confirmation: payload.new_password_confirmation,
      }),
    });

    if (response.ok) {
      sessionStorage.setItem("reset", "true");
      return { success: true };
    } else {
      const errorData = await response.json();
      console.error("Password reset error:", errorData);
      return {
        success: false,
        messages: ["passwordResetFailed"],
        details: errorData, // Include any additional details provided by the server.
      }; // Password reset failed with error details
    }
  } catch (error) {
    console.error("Error resetting password:", error);
    return {
      success: false,
      messages: ["errorOccurred"],
    }; // Error occurred while resetting password
  }
};

export const validatePassword = (password) => {
  const validations = [
    {
      regex: /[A-Z]/,
      message: "uppercase",
    },
    {
      regex: /[a-z]/,
      message: "lowercase",
    },
    {
      regex: /\d/,
      message: "number",
    },
    {
      test: (p) => p.length >= 8,
      message: "length",
    },
  ];

  const failedValidations = validations.flatMap((v) =>
    typeof v.regex !== "undefined"
      ? !v.regex.test(password)
        ? [v.message]
        : []
      : !v.test(password)
        ? [v.message]
        : []
  );

  return failedValidations.length === 0 ? true : failedValidations;
};

export const sign_out = async () => {
  try {
    // Retrieve the access token from localStorage
    const accessToken = Cookies.get('access_token');
    if (!accessToken) {
      // Handle the case when the access token is missing or invalid
      console.error("Access token is missing");
      return { success: false, message: "Access token is missing" };
    }
    const response = await fetch(`${BASE_API}/auth/sign_out`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`, // Include the user's access token
      },
    });
    if (response.ok) {
      // Clear the access token from storage (localStorage or sessionStorage)
      Cookies.remove('access_token');
      return { success: true };
    } else {
      // Handle errors, e.g., if the server returns a different status code
      console.error("Logout failed");
      return { success: false, message: "Logout failed" };
    }
  } catch (error) {
    console.error("Logout error:", error.message);
    return { success: false, message: "An error occurred while logging out" };
  }
};

export const sign_in = async (credentials) => {
  // Check if credentials are valid
  if (!credentials.username || !credentials.password) {
    return {
      success: false,
      message: "Please enter both username and password",
    };
  }
  try {
    // Set header for Accept-Language based on the language parameter
    const headers = {
      "Content-Type": "application/json",
      "Content-Language": i18n.language,
    };
    const response = await fetch(`${BASE_API}/auth/sign_in`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(credentials),
    });
    if (response.ok) {
      const { data } = await response.json();
      return data;
    } else {
      const { message } = await response.json();
      return {
        success: false,
        message: message,
      };
    }
  } catch (error) {
    console.error("Login error:", error.message);
    return { success: false, message: "An error occurred while logging in" };
  }
};
export const refresh_token = async (refreshToken) => {
  try {
    const response = await fetch(`${BASE_API}/auth/refresh_token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Content-Language": i18n.language,
      },
      body: JSON.stringify({ refresh_token: refreshToken }),
    });

    if (response.ok) {
      const { data } = await response.json();
      Cookies.set('access_token', data.access_token);
      return { success: true };
    } else {
      const errorData = await response.json();
      return {
        success: false,
        message: errorData.message || "Token refresh failed",
      };
    }
  } catch (error) {
    console.error("Error refreshing token:", error.message);
    return {
      success: false,
      message: "Error occurred while refreshing token",
    };
  }
};