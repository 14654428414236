import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import FacebookPixel from "src/components/FacebookPixel";
const fbPixel = process.env.REACT_APP_FB_PIXEL;

const Tracking = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const egdPaths = [
    t("routes.egd"),
    t("routes.egdGameList"),
    t("routes.newAndPopular"),
    t("routes.progressiveJackpots"),
    t("routes.electronicTableGames"),
  ];
  // const junePointsRafflePath = t("routes.junePointsRaffle");
  // const freJunePointsRafflePath = t("routes.freJunePointsRaffle");
  let pixelId;
  if (egdPaths.includes(pathname)) {
    pixelId = "386328838752434";
    // } else if (location.pathname === junePointsRafflePath) {
    //   pixelId = "461218361307529";
    // } else if (location.pathname === freJunePointsRafflePath) {
    //   pixelId = "824832714561099";
  } else {
    pixelId = "186368722230522"; // Default Playground Pixel ID
  }
  return (
    <>
      {fbPixel === "true" && pixelId && (
        <FacebookPixel pixelId={pixelId} />
      )}

      <Helmet>
        <meta property="fb:app_id" content="237301057100359" />
        <meta
          name="google-site-verification"
          content="C8LN9tLweFdUIogXbJFhwdmFErm3Qxif0LaKEmamu20"
        />
        <meta name="msvalidate.01" content="80C6DE5EB25D9B1DEA4F3A96C56D4612" />
        {process.env.REACT_APP_GA_TRACKING === "true" && (
          <>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=UA-20575458-1"
            ></script>
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'UA-20575458-1'); /* PLAYGROUND */
                gtag('config', 'AW-856515186'); /* CONVERSION */
                gtag('config', 'AW-785295507'); /* TEMPORARY */
              `}
            </script>
          </>
        )}
        {/* <!-- LUCKY ORANGE -->
        <script type='text/javascript'>
            window.__lo_site_id = 94046;

            (function() {
                var wa = document.createElement('script'); wa.type = 'text/javascript'; wa.async = true;
                wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
                var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(wa, s);
              })();
        </script>
        <!-- TRACKING LINK 3RD PARTY -->
        <!-- <script async src='https://tag.simpli.fi/sifitag/1c8403d0-676b-0136-d2b6-06a9ed4ca31b'></script> --> */}
      </Helmet>
    </>
  );
};

export default Tracking;
