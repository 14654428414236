import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown';
import Card from 'react-bootstrap/Card';
import Collapse from "react-bootstrap/Collapse";
import {
  tournamentTypeToString,
  getLateRegLevel,
  maxPlayersString
} from 'src/utils/tournamentUtils';
import {
  FaClock,
  FaDollarSign,
  FaTrophy,
  FaDownload,
  FaCaretDown,
  FaUsers
} from 'react-icons/fa';

import TournamentDetailsCardButtons from "./TournamentDetailsCardButtons";
import { formatDate, formatAmount } from "src/utils/formatters";
import CountdownList from "../CountdownList";
import { displayValue } from "src/utils/displayValue";
import { formatTimeString } from "src/utils/timeUtils";

const TournamentDetailsCard = ({ title, tournamentDetails, currentLocale, structure_file_url, collapseDetails = false }) => {
  const { t, i18n } = useTranslation();
  const [more, setMore] = useState(collapseDetails ? "" : `details_${tournamentDetails.id}`);

  const handleMoreFn = (action) => {
    more !== action ? setMore(action) : setMore("");
  };

  return (
    <TournamentCardDiv className={`
      tournament-card
      tournament-type-${tournamentTypeToString(tournamentDetails.type)}
      tournament-id-${tournamentDetails.id}
    `}>
      {tournamentDetails && (
        <>
          <Card.Body>
            <Card.Title>
              <h5>
                <span className="date-container">
                  {formatDate(tournamentDetails.start_date, currentLocale, 'short')}
                </span>
                <span className={`tournament-title tournament-type-${tournamentTypeToString(tournamentDetails.type)}`}>
                  {title}
                </span>
              </h5>
              {tournamentDetails?.[`target_${currentLocale}`] && (
                <h6 className={`tournament-type-${tournamentTypeToString(tournamentDetails.type)}`}>
                  {tournamentDetails[`target_${currentLocale}`]}
                </h6>
              )}
              {tournamentDetails?.[`target_description_${currentLocale}`] && (
                <h6 className={`tournament-type-${tournamentTypeToString(tournamentDetails.type)}`}>
                  {tournamentDetails[`target_description_${currentLocale}`]}
                </h6>
              )}
            </Card.Title>
            <div className={`card-text tournament-details tournament-type-${tournamentTypeToString(tournamentDetails.type)}`}>
              <CountdownList
                start_at={tournamentDetails.start_at}
                end_entry_time={tournamentDetails.end_entry_time}
              />
              <h6 onClick={() => handleMoreFn(`details_${tournamentDetails.id}`)}>
                {t("tournament.card.tournamentDetails")}
                {collapseDetails && <FaCaretDown className={`caret-icon ${more === `details_${tournamentDetails.id}` ? 'show' : ''}`} />}
              </h6>

              <Collapse in={!collapseDetails || more === `details_${tournamentDetails.id}`}>
                <div>
                  {tournamentDetails.starting_chips && (
                    <ReactMarkdown>
                      {t("tournament.card.playersStartWith", {
                        chips: formatAmount(tournamentDetails.starting_chips, currentLocale, false)
                      })}
                    </ReactMarkdown>
                  )}

                  {tournamentDetails.blinds && (
                    <ReactMarkdown>
                      {t("tournament.card.blindsIncrease", { minutes: tournamentDetails.blinds })}
                    </ReactMarkdown>
                  )}

                  {tournamentDetails.max_players > 0 && (
                    <ReactMarkdown>
                      {maxPlayersString(tournamentDetails.max_players, tournamentDetails.alternates, t)}
                    </ReactMarkdown>
                  )}

                  {tournamentDetails.late_registration_level && (
                    <ReactMarkdown>
                      {t("tournament.card.lateRegistration", {
                        level: getLateRegLevel(tournamentDetails.late_registration_level)
                      })}
                    </ReactMarkdown>
                  )}
                </div>
              </Collapse>
            </div>

            <div className={`card-text tournament-table tournament-type-${tournamentTypeToString(tournamentDetails.type)}`}>
              <span>
                <span className="icon-holder">
                  <FaClock />
                </span>
                <strong>{t("tournament.card.time")}&nbsp;</strong>
                {formatTimeString(tournamentDetails.start_at, i18n.language)}
              </span>

              <span>
                <span className="icon-holder">
                  <FaDollarSign />
                </span>
                <strong>{t("tournament.card.price")}&nbsp;</strong>
                {formatAmount(tournamentDetails.price, currentLocale)}
              </span>

              {tournamentDetails?.guarantee_amount > 0  ? (
                <span>
                  <span className="icon-holder">
                    <FaTrophy />
                  </span>
                  <strong>{t("tournament.card.guarantee")}&nbsp;</strong>
                  {displayValue(tournamentDetails, currentLocale)}
                </span>
              ) : (
                tournamentDetails?.max_players > 0 && (
                  <span>
                    <span className="icon-holder">
                      <FaUsers />
                    </span>
                    <strong>{t("tournament.card.max")}&nbsp;</strong>
                    <ReactMarkdown>
                      {maxPlayersString(tournamentDetails.max_players, tournamentDetails.alternates, t, true)}
                    </ReactMarkdown>
                  </span>
                )
              )}

              {structure_file_url && (
                <span>
                  <span className="icon-holder">
                    <FaDownload />
                  </span>
                  <a href={structure_file_url} target="_blank" rel="noopener noreferrer">
                    <strong>{t("tournament.card.tournamentStructure")}</strong>
                  </a>
                </span>
              )}
            </div>
            <Card.Footer>
              <TournamentDetailsCardButtons tournamentDetails={tournamentDetails} />
            </Card.Footer>
          </Card.Body>
        </>
      )}
    </TournamentCardDiv>
  );
};

const TournamentCardDiv = styled(Card)`
  border-radius: 0;
  color: var(--white);
  background-color: var(--black);
  border: solid 1px var(--darkgrey);
  /* border: solid 4px transparent; */
  /* &.tournament-type-festival {
    border-color: var(--fc-tournament-festival-muted);
  }
  &.tournament-type-online {
    border-color: var(--fc-tournament-online-muted);
  }
  &.tournament-type-satellite {
    border-color: var(--fc-tournament-satellite-muted);
  }
  &.tournament-type-daily {
    border-color: var(--fc-tournament-daily-muted);
  } */
  width: 100%;
  height: 100%;
  /* padding: 1rem; */
  background-color: var(--black);
  color: var(--white);
  text-align: center;
  padding: 0rem;
  @media (min-width: 668px) {
    padding: 1rem;
  }
  @media (min-width: 992px) {
    padding: 1.3rem 2rem 2rem;
  }

  .card-title {
    h5 {
      font-size: 1.5rem;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      .tournament-title {
        display: block;
        padding: 1rem;
        margin: 1rem 0 0;
        font-size: 1.5rem;
        font-weight: bold;
        @media (min-width: 767.98px) {
          display: flex;
          align-items: center;
          text-align: center;
          min-height: 8rem;
          flex-direction: column;
          justify-content: center;
        }
        &.tournament-type-daily {
          background-color: var(--fc-tournament-daily);
        }
        &.tournament-type-satellite {
          background-color: var(--fc-tournament-satellite);
        }
        &.tournament-type-online {
          background-color: var(--fc-tournament-online);
        }
        &.tournament-type-festival {
          background-color: var(--fc-tournament-festival);
        }
      }
    }
    h6 {
      margin: 0;
      padding: 0 1rem 0.7rem;
      &:first-of-type {
        padding-top: 1.1rem!important;
      }
      &:last-of-type {
        padding-bottom: 1.1rem!important;
      }
      &.tournament-type-daily {
        background-color: var(--fc-tournament-daily-muted);
      }
      &.tournament-type-satellite {
        background-color: var(--fc-tournament-satellite-muted);
      }
      &.tournament-type-online {
        background-color: var(--fc-tournament-online-muted);
      }
      &.tournament-type-festival {
        background-color: var(--fc-tournament-festival-muted);
      }
    }
  }

  @media (max-width: 767.98px) {
    .card-title h5 {
      font-size: 1.4rem;
      .tournament-title {
        font-size: 1.2rem;
        margin-top: 1.2rem;
      }
    }
  }

  .card-text {
    margin-bottom: 0;
    &.tournament-details {
      padding: 1rem;
      h6 {
        font-weight: bold;
        font-size: 1.2rem;
        white-space: nowrap;
        cursor: pointer;
        .caret-icon {
          transition: transform 0.3s ease;
        }
        .caret-icon.show {
          transform: rotate(180deg);
        }
      }
      p {
        line-height: 1rem;
        margin: 0 0 0.5rem;
        font-size: 0.9rem;
        &:before {
          content: "• ";
        }
      }
      &.tournament-type-daily {
        background-color: var(--fc-tournament-daily-muted);
      }
      &.tournament-type-satellite {
        background-color: var(--fc-tournament-satellite-muted);
      }
      &.tournament-type-online {
        background-color: var (--fc-tournament-online-muted);
      }
      &.tournament-type-festival {
        background-color: var(--fc-tournament-festival-muted);
      }

    }

    &.tournament-table {
      color: var(--black);
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: space-around;
      padding: 1rem 0;

      > span {
        background-color: var(--white);
        text-align: left;
        font-size: 1.2rem;
        display: flex;
        padding-right: 0.5rem;
        p {
          display: inline;
          margin: 0;
          font-size: unset;
        }
      }
      &.tournament-type-daily {
        .icon-holder {
          background-color: var(--fc-tournament-daily);
        }
      }
      &.tournament-type-satellite {
        .icon-holder {
          background-color: var(--fc-tournament-satellite);
        }
      }
      &.tournament-type-online {
        .icon-holder {
          background-color: var(--fc-tournament-online);
        }
      }
      &.tournament-type-festival {
        .icon-holder {
          background-color: var(--fc-tournament-festival);
        }
      }
      .icon-holder {
        width: 2.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
        svg {
          color: var(--white);
        }
      }
    }
  }

  .card-footer {
    padding: 0;
    .alert {
      border-radius: 0;
      padding: 0.6rem;
      margin-top: 0.6rem;
    }
    .alert, button {
      margin-bottom: 0;
    }
  }
`;

export default TournamentDetailsCard;
