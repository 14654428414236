import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import ReactMarkdown from "react-markdown";
import TitleSection from "src/components/TitleSection/TitleSection";
import CashGamesInfo from "src/components/Poker/CashGamesInfo";
import EventFinished from "src/components/EventFinished";
import Button from "src/components/Button/Button";
import { Col, Row } from "react-bootstrap";
import ImageLink from "src/components/ImageWrapper/ImageLink";

const HighHandFebruary2025 = () => {
  const { i18n, t } = useTranslation();

  return (
    <HighHandDiv>
      <Hero
        bgImage="promotions/poker/high-hand-february-2025/hero-image-2400x800px.jpg"
        image={`promotions/poker/high-hand-february-2025/HH-logo-2400x2400_${i18n.language}.png`}
        imageAlt="High Hand January 2025"
      />
      <EventFinished
        scheduleKeyName="highHandFebruary2025"
        eventType="promotion"
      />
      <container-div>
        <div className="high-hand">
          <div>
            <TitleSection
              as={"h2"}
              title={t("poker.high-hand-february-2025.highHand.title")}
              text={t("poker.high-hand-february-2025.highHand.text")}
            />
            <div>
              <ReactMarkdown>
                {t("poker.high-hand-february-2025.highHand.smallInfo")}
              </ReactMarkdown>
              <StyledRow className="d-flex">
                <StyledCol xs={12} md={6}>
                  <ImageLink
                    to={t("routes.titanTuesday2025")}
                    imgSrc={`promotions/poker/titan-tuesday-2025/TT-logo-2400x2400px-${i18n.language}.png`}
                    alt={"Titan Tuesday 2025"}
                    className="card-image-top"
                  />
                </StyledCol>
                <StyledCol xs={12} md={6}>
                  <ReactMarkdown>
                    {t("poker.high-hand-february-2025.highHand.titan.text")}
                  </ReactMarkdown>
                  <Button
                    className={"mt-0 mb-2"}
                    href={t("routes.titanTuesday2025")}
                    bgColor="var(--white)"
                    color="var(--black)"
                    hoverColor="var(--black)"
                    hoverBgColor="var(--white)"
                    label={t("button.learn-more")}
                    size="medium"
                  />
                </StyledCol>
              </StyledRow>
              <CashGamesInfo />
            </div>
          </div>
          <div>
            <h4>
              {t("poker.high-hand-february-2025.highHand.generalRules.title")}
            </h4>
            <ReactMarkdown>
              {t("poker.high-hand-february-2025.highHand.generalRules.list", {
                returnObjects: false,
              })}
            </ReactMarkdown>
          </div>
        </div>
      </container-div>
    </HighHandDiv>
  );
};
const HighHandDiv = styled.div`
  width: 100%;
  position: relative;
  background-color: #010101;
  .hero_text_container {
    display: none;
  }
  .title-section {
    padding: 40px 0 0 0;
  }
  .high-hand {
    margin: 20px 0;
    & > div:first-child {
      width: 100%;
      max-width: 1440px;
      height: auto;
      margin: 0 auto;
      color: var(--white);
      & > h3 {
        font-weight: 700;
        font-size: 20px;
        margin-top: 0px;
        padding-bottom: 20px;
        text-transform: uppercase;
      }
      & > div {
        width: 100%;
        & > h4 {
          background: rgb(170, 11, 11);
          background: linear-gradient(
            45deg,
            rgba(170, 11, 11, 1) 0%,
            rgba(0, 0, 0, 1) 50%,
            rgba(170, 11, 11, 1) 100%
          );
          color: var(--white);
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;
          padding: 15px;
          max-width: 500px;
          font-size: 30px;
          margin: 20px auto;
          border-radius: 5px;
          line-height: 25px;
        }
        & > p {
          margin: 0 0 10px;
          font-size: 18px;
          color: #aaa;
          text-align: center;
        }
      }
    }
    & > div:nth-child(2) {
      background-color: #111;
      width: 100%;
      max-width: 1440px;
      margin: 40px auto 0 auto;
      padding: 2rem 1rem;
      & > h4 {
        font-weight: 700;
        color: #999;
        text-transform: uppercase;
        text-align: center;
        padding: 15px;
        font-size: 30px;
        margin: 5px 0 10px 0;
      }
      & > ul {
        margin-top: 0;
        margin-bottom: 10px;
        list-style: decimal;
        & > li {
          font-size: 16px;
          color: #999;
          ul {
            list-style: lower-alpha;
            ul {
              list-style: lower-roman;
            }
          }
        }
      }
    }
  }
  .nav-link,
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    padding: 1rem;
    background-color: ${(props) =>
      props.bgColor || "var(--mutedred)"} !important;
    color: ${(props) => props.textColor || "white"};
    border-right: 1px solid
      ${(props) => props.borderColor || "var(--lightblack)"};
    border-bottom: 1px solid
      ${(props) => props.borderColor || "var(--lightblack)"};
    &:hover,
    &.active,
    &:focus {
      background-color: ${(props) => props.active || "var(--red)"} !important;
      color: ${(props) => props.textColor || "var(--white)"};
    }
  }
  .nav-pills {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .nav-link {
    width: 100%;
  }
  @media screen and (max-width: 991px) {
    .nav-pills {
      grid-template-columns: 1fr;
    }
    .high-hand {
      & > div:first-child {
        & > img {
          width: 100%;
        }
        & > div {
          & > h3 {
            font-size: 20px;
            line-height: 1.35em;
          }
        }
      }
    }
  }
`;
const StyledRow = styled(Row)``;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 991px) {
    p {
      font-size: 1.2rem;
    }
  }
`;
export default HighHandFebruary2025;
