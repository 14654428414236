import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useErs } from "src/context/ers/ers.provider";
import { useHomepage } from "src/context/homepage/homepage.provider";
import styled from "styled-components/macro";
import GameListHero from "src/components/ElectronicGamingDevices/GameListHero";
import TitleSection from "../components/TitleSection/TitleSection";
import HeroCarousel from "../components/Carousel/HeroCarousel";
import Hero from "../components/Hero/Hero";
import Jackpots from "../components/ElectronicGamingDevices/Jackpots";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";
import { Col, Row, Tab } from "react-bootstrap";
import Button from "../components/Button/Button";
import HashTabs from "../components/Tabbed/HashTabs";
import WinnerPic from "src/components/Jackpot/WinnerPic";
import BannerTitle from "src/components/TitleSection/BannerTitle";


const Egd = () => {
  const { t, i18n } = useTranslation();
  const { data, fetchData } = useErs();
  const { gamingMachineJackpotData } = useHomepage();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const bgImage = "egd/promo_bg.jpg";
  const overlayVideoSrc = "/videos/confetti.mp4";
  const overlayVideoSrc2 = "/videos/confetti-2.mp4";
  const seeTheGamesButton = {
    label: t("egd.egdGameList.buttonText"),
    href: t("routes.egdGameList"),
    size: "medium",
    width: "xwide",
    specialHover: true,
    className: "mb-5 mt-0",
  };

  // Fetch the carousel data when the component mounts or language changes
  useEffect(() => {
    fetchData(`carousel-egd-${i18n.language}`)
      .then(() => {
        setError(null);
      })
      .catch((err) => {
        setError(true);
        console.error("Error fetching carousel data:", err);
      });
  }, [fetchData, i18n.language]);

  return (
    <EgdDiv>
      <switcheroo-holder>
        <TitleSection
          title={t("egd.section-title")}
          text={t("egd.section-title-text")}
        />
        <HeroCarousel
          carouselData={
            data[`carousel-egd-${i18n.language}`] &&
            data[`carousel-egd-${i18n.language}`].length > 0
              ? data[`carousel-egd-${i18n.language}`]
              : [t("defaultHeroCarousel.loading", { returnObjects: true })]
          }
        />
      </switcheroo-holder>

      <center-content>
        <Button {...seeTheGamesButton} />
      </center-content>

      <GameListHero jackpotData={gamingMachineJackpotData} compact />

      <RecentJackpotSection
        bgImage={useResponsiveImageUrl(bgImage)}
        className="pb-5"
      >
        <container-div>
          {/* <TitleSection
            className="pb-0"
            title={t("egd.30-day-jackpots")}
            text={t("egd.30-day-jackpots-text")}
          /> */}

          <BannerTitle
            title={t("egd.jackpot-winners-title.title")}
            highlight={t("egd.jackpot-winners-title.highlight")}
            className="p-0"
          />
          <Row xs={1} md={2} className="g-4 pb-5">
            <Col>
              <WinnerPic
                imageSrc="winner-348_755.jpg"
                amount="348755"
                language={i18n.language}
              />
            </Col>
            <Col>
              <WinnerPic
                imageSrc="winner-78_955.jpg"
                amount="78955"
                language={i18n.language}
              />
            </Col>
            <Col>
              <WinnerPic
                imageSrc="winner-46_037.jpg"
                amount="46037"
                language={i18n.language}
              />
            </Col>
            <Col>
              <WinnerPic
                imageSrc="winner-30_443.jpg"
                amount="30443"
                language={i18n.language}
              />
            </Col>
          </Row>

          <HashTabs
            defaultActiveKey={t("egd.recentJackpots.hash")}
            id="egd-jackpot-tabs"
            tabs={[t("egd.recentJackpots.hash"), t("egd.topJackpots.hash")]}
          >
            <Tab
              eventKey={t("egd.recentJackpots.hash")}
              title={t("egd.recentJackpots.tab")}
            >
              <TabContent>
                <Jackpots
                  jackpotType={"recentJackpots"}
                  jackpotData={gamingMachineJackpotData}
                />
                <video autoPlay muted loop playsInline className="overlay-video">
                  <source src={overlayVideoSrc2} type="video/mp4" />
                </video>
              </TabContent>
            </Tab>
            <Tab
              eventKey={t("egd.topJackpots.hash")}
              title={t("egd.topJackpots.tab")}
            >
              <TabContent>
                <Jackpots
                  jackpotType={"topJackpots"}
                  jackpotData={gamingMachineJackpotData}
                />
                <video autoPlay muted loop playsInline className="overlay-video">
                  <source src={overlayVideoSrc} type="video/mp4" />
                </video>
              </TabContent>
            </Tab>
          </HashTabs>
        </container-div>
      </RecentJackpotSection>

      <Hero
        className="inline-hero mt-5 promo-hero"
        mainHeroText={t("egd.promo-hero.main-hero-text")}
        darkenBg={0.4}
        bgImage="home/offers-header.jpg"
        descriptionBgColor="#0000004d"
        overlayVideoSrc="/videos/bbj-overlay.mp4"
        buttonSettings={{
          label: t("egd.promo-hero.buttonSettings.label"),
          href: t("routes.egdPromotions"),
          size: "large",
          width: "xwide",
          specialHover: true,
        }}
      />
    </EgdDiv>
  );
};

const EgdDiv = styled.div`
  width: 100%;
  min-height: 100vh;
  background: var(--black);
  .inline-hero {
    // TODO: Move this to variant="inline" on the component
    padding-top: 0;
  }
  .promo-hero {
    @media (max-width: 768px) {
      h2 {
        font-size: 2rem;
      }
    }
  }
  .game-list-container {
    .hero_text_container {
      display: none;
    }
  }
`;

const RecentJackpotSection = styled.div`
  background-color: var(--black);
  padding: 20px;
  position: relative;
  container-div {
    padding: 0;
    z-index: 2;
    position: relative;
    > .title-section {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
  .title-section,
  .sectional-tabs {
    z-index: 2;
    position: relative;
  }
  .banner-highlight-text {
    font-size: ${({ children }) => `clamp(1rem, ${(100 / children.length) + 2}vw, 6rem)`};
  }
  video.overlay-video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    display: inline-block;
    /* opacity: 0.7; */
    object-fit: cover;
  }

`;

const TabContent = styled.div`
  background-color: var(--lightblack);
  position: relative;
  /* background-color: rgba(255, 255, 255, 0.5); */  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 420px;
    /* background-color: rgba(0, 0, 0, 0.9); */
    background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
    z-index: 1;
    display: none;
  }
`;

export default Egd;
