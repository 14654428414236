import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { tournaments, tournament } from "../../api/poker";
import TournamentDetailsCard from "./Tournaments/TournamentDetailsCard";
import SubTitleSection from "../SubTitleSection/SubTitleSection";
import { TbRectangleVerticalFilled } from "react-icons/tb";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import {
  useIsXsScreenDown,
  useIsSmallScreenOnly,
  useIsSmScreenDown,
  useIsMediumScreenOnly,
  useIsLgScreenUp,
} from "src/utils/windowUtils";
import Button from "../Button/Button";
import moment from "moment";
import Spinner from "../Spinner";

const Tournaments = () => {
  const { i18n, t } = useTranslation();
  const currentLocale = i18n.language;
  const upcomingDays = 12;

  const [tournamentsData, setTournamentsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);

  const sliderRef = useRef(null);

  // Screen size detection
  const isXsScreenDown = useIsXsScreenDown();
  const isSmScreenOnly = useIsSmallScreenOnly();
  const isSmScreenDown = useIsSmScreenDown();
  const isMediumScreenOnly = useIsMediumScreenOnly();
  const isLgScreenUp = useIsLgScreenUp();

  // Determine slides to show based on screen size
  let slidesToShow = 1;
  let limit = 6; // Default limit for xs screens

  if (isSmScreenOnly) limit = 12;
  else if (isMediumScreenOnly) {
    limit = 24;
    slidesToShow = 2;
  } else if (isLgScreenUp) {
    limit = tournamentsData.length; // Show all for large screens
    slidesToShow = 3;
  }

  const limitedTournamentsData = tournamentsData.slice(0, limit);

  // Reset slide index when screen size changes
  useEffect(() => {
    setSlideIndex(0);
    if (sliderRef.current) sliderRef.current.slickGoTo(0);
  }, [isXsScreenDown, isSmScreenOnly, isMediumScreenOnly, isLgScreenUp]);

  // Fetch tournaments
  useEffect(() => {
    const fetchTournaments = async () => {
      setLoading(true);
      try {
        const startDate = moment().format("YYYY-MM-DD");
        const endDate = moment().add(upcomingDays, "days").format("YYYY-MM-DD");

        const basicData = await tournaments(startDate, endDate);
        const detailedData = await Promise.all(
          basicData.map(async (tournamentBasic) => {
            try {
              const details = await tournament(tournamentBasic.gid);
              return { ...tournamentBasic, ...details };
            } catch (error) {
              console.error("Error fetching tournament details:", error);
              return null;
            }
          })
        );

        const futureTournaments = detailedData
          .filter((t) => t !== null)
          .filter((tournament) => {
            const endEntryMoment = tournament.end_entry_time
              ? moment(tournament.end_entry_time)
              : moment(tournament.start_at);
            return endEntryMoment.isAfter(moment());
          });

        setTournamentsData(futureTournaments);
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTournaments();
  }, []);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    lazyLoad: "ondemand",
    speed: 800,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    adaptiveHeight: true,
    // initialSlide: slideIndex,
    beforeChange: (current, next) => setSlideIndex(next),
    customPaging: () => <TbRectangleVerticalFilled />,
    appendDots: (dots) => <ul>{dots}</ul>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  if (loading) {
    return (
      <Overlay>
        <Spinner variant="dark" />
      </Overlay>
    );
  }

  return (
    <TournamentsDiv>
      <container-wide>
        <Button
          className="all-tournaments-btn"
          color="var(--white)"
          hoverBgColor="var(--white)"
          hoverColor="var(--black)"
          href={t("routes.pokerTournamentCalendar")}
          label={t("poker.upcomingTournaments.viewAll")}
          fluid={isSmScreenDown ? true : false}
        />
        <SubTitleSection
          subTitle={t("poker.upcomingTournaments.title")}
          text={t("poker.upcomingTournaments.upcomingDays", {
            days: upcomingDays,
          })}
        />
        <TournamentSlider {...settings} ref={sliderRef}>
          {limitedTournamentsData?.map((eventDetails) => (
            <TournamentDetailsCard
              key={eventDetails?.id}
              collapseDetails={true}
              title={eventDetails[`name_${currentLocale}`]}
              tournamentDetails={eventDetails}
              currentLocale={currentLocale}
              structure_file_url={
                eventDetails[`structure_file_${currentLocale}`]
              }
            />
          ))}
        </TournamentSlider>
      </container-wide>
    </TournamentsDiv>
  );
};

const Overlay = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const TournamentsDiv = styled.div`
  width: 100%;
  background: var(--lightblack);
  display: flex;
  flex-direction: column;
  padding: 2rem 0 0;
  .all-tournaments-btn {
    order: 1;
  }
  .tournament-card {
    height: 100% !important;
    max-height: auto;
  }
  @media (min-width: 768px) {
    container-wide {
      display: block;
      padding-bottom: 2rem;
    }
    .all-tournaments-btn {
      float: right;
    }
  }
`;

const TournamentSlider = styled(Slider)`
  width: 100%;
  display: flex;
  flex-direction: column;
  .slick-dots {
    bottom: 0;
    position: relative;
    top: 40px;
    margin-bottom: 50px;
    ul {
      margin: 0;
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li {
      opacity: 0.5;
      transition: all 0.3s;
      color: var(--white);
      &.slick-active {
        opacity: 1;
        svg {
          transform: scale(1.5);
        }
      }
    }
  }
  .slick-list {
    margin: 0 -10px;
    padding: 0 10px;
    overflow-y: visible;
    transition: all 0.4s;
    height: auto !important;
  }
  .slick-track {
    display: flex;
    align-items: stretch;
  }
  .slick-slide {
    padding: 0 10px;
    height: auto !important;
    align-items: stretch;
  }
  .card {
    padding: 0;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .collapse {
      overflow: hidden;
    }
  }
  @media (max-width: 768px) {
    .slick-slider {
      overflow: hidden; /* Prevent extra spacing */
    }

    .slick-list {
      overflow: hidden !important;
    }

    .slick-track {
      display: flex !important;
      align-items: center;
    }

    .slick-slide {
      padding-right: 30px;
      flex: 1 !important; /* Prevent shrinking */
      width: 100% !important; /* Ensure full width */
    }
  }
`;

export default Tournaments;
