import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";

const splitUrl = (url) => {
  const [pathname, hash] = url.split("#");
  return { pathname, hash: hash ? `#${hash}` : "" };
};

const Button = ({
  href,
  label,
  bgColor,
  color,
  hoverBgColor,
  hoverColor,
  size,
  specialHover,
  fluid,
  width,
  onClick,
  className,
  type,
  disabled,
  replace,
}) => {
  const navigate = useNavigate();
  const isExternalLink = href && href.startsWith("http");

  const handleClick = (event) => {
    if (onClick) {
      event.preventDefault();
      onClick(event);
    }

    if (!href || isExternalLink) return;

    event.preventDefault();
    const { pathname, hash } = splitUrl(href);
    navigate({ pathname, hash }, { replace });
  };

  const { pathname, hash } = href ? splitUrl(href) : { pathname: "", hash: "" };
  const ComponentProp = href ? (isExternalLink ? "a" : Link) : "button";

  return (
    <LinkButton
      as={ComponentProp}
      to={!isExternalLink && href ? { pathname, hash, replace } : undefined}
      href={isExternalLink ? href : undefined}
      target={isExternalLink ? "_blank" : undefined}
      rel={isExternalLink ? "noopener noreferrer" : undefined}
      bgColor={bgColor}
      color={color}
      hoverColor={hoverColor}
      hoverBgColor={hoverBgColor}
      size={size}
      width={width}
      specialHover={specialHover}
      fluid={fluid}
      className={`${disabled ? "disabled" : ''} ${className}`}
      onClick={handleClick}
      type={type}
      disabled={disabled}
    >
      <div>
        <span>{label}</span>
      </div>
    </LinkButton>
  );
};

// Utility functions for padding calculations
const getPaddingValue = (padding) =>
  padding.split(" ").map((pad) => parseFloat(pad.replace("rem", "")));

const sizes = {
  xsmall: { fontSize: "0.75rem", fontWeight: "600", padding: "0.5rem 1rem" },
  small: { fontSize: "0.8rem", fontWeight: "400", padding: "0.6rem 1.5rem" },
  medium: { fontSize: "1rem", fontWeight: "400", padding: "0.8rem 2rem" },
  large: { fontSize: "1.25rem", fontWeight: "700", padding: "1rem 2.5rem" },
  xlarge: { fontSize: "2rem", fontWeight: "700", padding: "1.5rem 3rem" },
};

const widthPaddingAdjustments = {
  fit: -1,
  compact: 0,
  regular: 1,
  wide: 3,
  xwide: 5,
};

const calculatePadding = (size, width) => {
  const sizePadding = sizes[size]?.padding || sizes.medium.padding;
  const [paddingTopBottom, paddingLeftRight] = getPaddingValue(sizePadding);
  const widthAdjust = widthPaddingAdjustments[width] || 0;
  return `${paddingTopBottom}rem ${paddingLeftRight + widthAdjust}rem`;
};

const LinkButton = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    !["bgColor", "color", "hoverBgColor", "hoverColor", "size", "width", "specialHover", "fluid"].includes(prop),
})`
  :disabled, &.disabled {
    opacity: 0.4;
    background: grey;
    cursor: not-allowed;
    &:hover {
      color: ${(props) => props.color || "var(--white)"};
      background: grey;
    }
  }
  line-height: 1.2;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  position: relative;
  border: none;
  z-index: 2;
  transition: all 0.5s;
  border-radius: 5px;
  margin: 1.2rem auto;
  padding: ${(props) => calculatePadding(props.size, props.width)};
  font-size: ${(props) =>
    sizes[props.size]?.fontSize || sizes.medium.fontSize};
  font-weight: ${(props) =>
    sizes[props.size]?.fontWeight || sizes.medium.fontWeight};
  color: ${(props) => props.color || "var(--white)"};
  text-decoration: none;
  width: ${(props) => (props.fluid ? "100%" : "auto")};
  max-width: 100%;
  &.no-spacing {
    margin: 0;
  }
  @media (max-width: 426px) {
    padding: ${(props) => calculatePadding(props.size, "fit")};
    width: 100%;
  }

  ${(props) =>
    props.specialHover
    ? css`
          &:hover {
            color: ${(props) => props.hoverColor || "var(--white)"};
          }

          &::before,
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            transition: all 0.5s;
          }

          &::before {
            z-index: -1;
            background: ${props.bgColor || "var(--red)"};
          }

          &:hover::before {
            opacity: 0;
            transform: scale(0.5);
          }

          &::after {
            z-index: 1;
            opacity: 0;
            border: 2px solid ${props.hoverColor || "var(--white)"};
            @media (min-width: 768px) {
              transform: scale(1.2);
            }
          }

          &:hover::after {
            opacity: 1;
            transform: scale(1);
          }
        `
      : css`
          background: ${props.bgColor || "var(--red)"};

          &:hover, &.active {
            color: ${props.hoverColor || "var(--black)"};
            background: ${props.hoverBgColor || "white"};
          }
        `}
`;

Button.propTypes = {
  href: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverBgColor: PropTypes.string,
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large", "xlarge"]),
  specialHover: PropTypes.bool,
  fluid: PropTypes.bool,
  width: PropTypes.oneOf(["fit", "compact", "regular", "wide", "xwide"]),
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  replace: PropTypes.bool,
};

Button.defaultProps = {
  bgColor: "var(--red)",
  hoverBgColor: "white",
  size: "medium",
  specialHover: false,
  fluid: false,
  width: "regular",
  onClick: undefined,
  type: "button",
  disabled: false,
  replace: false,
};

export default Button;
