import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { email_verification } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";
import { Spinner } from "react-bootstrap";

const EmailConfirmation = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [isProcessingConfirmation, setIsProcessingConfirmation] = useState(true);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [message, setMessage] = useState(null);
  // const [isTesting, setIsTesting] = useState(true); // Added for testing

  // Extract 'gid' from URL query parameter
  const urlParams = new URLSearchParams(window.location.search);
  const gid = urlParams.get("gid");

  useEffect(() => {
    if (!gid) {
      setMessage(t("emailConfirmation.noGidfound"));
      setIsProcessingConfirmation(false);
      return;
    }

    // Simulate API behavior for testing
    // if (isTesting) {
    //   setTimeout(() => {
    //     const success = true; // Toggle between true/false to simulate success/failure
    //     if (success) {
    //       setConfirmEmail(true);
    //       setMessage("Simulated email confirmation success!");
    //       setTimeout(() => {
    //         // navigate(t("routes.homepage"), { replace: true });
    //         // window.location.reload();
    //       }, 3000);
    //     } else {
    //       setConfirmEmail(false);
    //       setMessage("Simulated email confirmation failed!");
    //     }
    //     setIsProcessingConfirmation(false);
    //   }, 2000); // Simulated delay for testing
    //   return;
    // }

    // Real API call (comment out for testing)
    if (gid) {
      // Function to process email verification asynchronously
      async function verifyEmail(gid) {
        try {
          const response = await email_verification(gid);
          if (response.success) {
            setConfirmEmail(true); // Show success message or state
            setMessage(response.message);
          }else {
            setMessage(response.message);
          }
        } catch (error) {
          console.error("An error occurred during email confirmation:", error);
        } finally {
          setIsProcessingConfirmation(false); // End processing in any case
        }
      }
      verifyEmail(gid);
    }
  }, [gid, i18n.language]);

  return (
    <MainDiv bgImage={useResponsiveImageUrl("reg/bg-login.png")}>
      <div>
        <div>
          {isProcessingConfirmation ? (
            // Show loader while processing
            <LoaderWrapper>
              <Spinner animation="border" variant="light" />
              <p>{t("emailConfirmation.processing")}</p>
            </LoaderWrapper>
          ) : (
            <div>
              {confirmEmail ? (
                <>
                  <h3>{t("emailConfirmation.successTitle")}</h3>
                  <div>
                    <div className="email-success">
                      <ResponsiveImage src="svg/Check.svg" alt="check" />
                      <h4>{t("emailConfirmation.welcomeText")}</h4>
                      <h4 className="email-response-message">{message}</h4>
                      <p>{t("emailConfirmation.thankYouMessage")}</p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h3>{t("emailConfirmation.errorTitle")}</h3>
                  <div>
                    <div className="email-success">
                      <h4 className="email-response-message">{message}</h4>
                      <p>{t("emailConfirmation.retryMessage")}</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </MainDiv>
  );
};

const MainDiv = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
  & > div:first-child {
    height: auto;
    min-height: 900px;
    width: 100%;
    background-image: url(${(props) => props.bgImage});
    padding: 100px 0 20px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width: 100%;
      max-width: 570px;
      height: auto;
      div {
        & > h3 {
          font-size: 22px;
          font-weight: 700;
          margin: 0px;
          padding-bottom: 5px;
          border-bottom: 1px solid var(--red);
          margin-bottom: 30px;
          color: var(--white);
        }
        & > div {
          background: rgba(0, 0, 0, 0.5);
          padding: 30px;
          text-align: center;
          color: var(--white);
          & > div {
            padding: 20px;
            background: rgba(0, 0, 0, 0.7);
            & > img {
              width: 50px;
              height: 50px;
              object-fit: contain;
              margin: 10px auto;
            }
            & > h4:nth-child(2) {
              font-weight: normal;
              margin: 0;
              padding-bottom: 15px;
              border-bottom: 1px solid var(--rewards_grid);
            }
            & > p {
              & > strong {
                & > a {
                  color: var(--red) !important;
                  &:hover {
                    border-bottom: 1px solid var(--red);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > div:first-child {
      min-height: 80vh;
      padding: 100px 20px 40px 20px;
      & > div {
        & > div {
          & > div {
            padding: 10px;
          }
        }
      }
    }
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--white);
  text-align: center;
`;

export default EmailConfirmation;