import React, { useCallback, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { useAccount } from "../context/account/account.provider";
import { send_verification_email } from "../api/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "./Button/Button";
import ReactMarkdown from "react-markdown";
import { t } from "i18next";
import { useToast } from "src/context/toast/toast.provider";

const EMAIL_VERIFICATION_DELAY = 2000;

const EmailConfirmationBanner = () => {
  const { access_token, accountInfo } = useAccount();
  const { showToastFn } = useToast();
  const [emailSend, setEmailSend] = useState(false);
  const [closeBanner, setCloseBanner] = useState(true);
  const [isProcessingVerification, setIsProcessingVerification] =
    useState(false);

  const resetProcessingState = useCallback(() => {
    setTimeout(
      () => setIsProcessingVerification(false),
      EMAIL_VERIFICATION_DELAY
    );
  }, []);

  const handleEmailVerification = () => {
    // if (!access_token || isProcessingVerification){
    //   showToastFn({
    //     show: true,
    //     title: t("modal.tokenExpired.error.title"),
    //     message: t("modal.tokenExpired.error.message"),
    //     autohide: true,
    //     variant: "danger",
    //   });
    // }
    setIsProcessingVerification(true);

    send_verification_email(access_token)
      .then((response) => {
        resetProcessingState();
        if (response.success) {
          setEmailSend(true);
          showToastFn({
            show: true,
            title: t("modal.tokenExpired.success.title"),
            message: t("auth.verifyEmail.sent"),
            autohide: false,
            variant: "success",
          });
          setCloseBanner(true);
        } else {
          showToastFn({
            show: true,
            title: t("modal.tokenExpired.error.title"),
            message: response.message,
            autohide: false,
            variant: "danger",
          });
        }
      })
      .catch((error) => {
        resetProcessingState();
        console.error("An error occurred:", error);
      });
  };

  useLayoutEffect(() => {
    if (accountInfo?.meta?.verified_email !== undefined) {
      setCloseBanner(accountInfo.meta.verified_email);
      // To test the no email banner, set hasEmail to false
      // setCloseBanner(false);
    }
  }, [accountInfo?.meta?.verified_email]);

  const hasEmail = !!accountInfo?.data?.email;
  // To test the no email banner, set hasEmail to false
  // const hasEmail = false;

  if (closeBanner) return null;

  return (
    <>
      {hasEmail ? (
        !emailSend && (
          <VerificationRequiredBanner
            handleEmailVerification={handleEmailVerification}
            isProcessingVerification={isProcessingVerification}
            setCloseBanner={setCloseBanner}
          />
        )
      ) : (
        <NoEmailBanner setCloseBanner={setCloseBanner} />
      )}
    </>
  );
};

const VerificationRequiredBanner = ({
  handleEmailVerification,
  isProcessingVerification,
  setCloseBanner,
}) => (
  <Banner className="email-verification-banner">
    <p>
      <FontAwesomeIcon icon={faEnvelope} aria-hidden="true" />
      &nbsp;
      {t("auth.verifyEmail.title")}
    </p>
    <p className="small">{t("auth.verifyEmail.desc")}</p>
    <ButtonContainer>
      <Button
        onClick={handleEmailVerification}
        bgColor="var(--lightblack)"
        hoverBgColor="var(--red)"
        hoverColor="var(--white)"
        disabled={isProcessingVerification}
        label={t("auth.verifyEmail.sendEmail")}
        id="send-email-verification"
      />
      <Button
        label={t("auth.verifyEmail.later")}
        onClick={() => setCloseBanner(true)}
        bgColor="var(--white)"
        color="grey"
        hoverBgColor="var(--red)"
        hoverColor="var(--white)"
      />
    </ButtonContainer>
  </Banner>
);

// const EmailSentBanner = () => (
//   <Banner>
//     <p>{t("auth.verifyEmail.sent")}</p>
//   </Banner>
// );

const NoEmailBanner = ({ setCloseBanner }) => (
  <Banner className="no-email">
    <FontAwesomeIcon
      className="close-icon"
      icon={faTimes}
      aria-hidden="true"
      onClick={() => setCloseBanner(true)}
    />
    <h5>
      <strong>
        <FontAwesomeIcon icon={faEnvelope} aria-hidden="true" />{" "}
        {t("auth.noEmailConnected.title")}
      </strong>
    </h5>
    <p>{t("auth.noEmailConnected.emailAdvantage")}</p>
    <ReactMarkdown className="contact-ps">
      {t("auth.noEmailConnected.desc")}
    </ReactMarkdown>
  </Banner>
);

const Banner = styled.div`
  position: fixed;
  transition: all 0.3s linear;
  bottom: 0;
  background: var(--mutedwhite);
  color: var(--lightblack);
  z-index: 6;
  width: 100%;
  height: auto;
  padding: 20px;
  text-align: left;
  @media (min-width: 768px) {
    text-align: center;
  }
  button {
    line-height: 1.2rem;
  }
  &.no-email {
    background: var(--mustard);
    h5 {
      font-size: 1.2rem;
      margin-top: 0;
    }
  }
  .close-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
  }
  .contact-ps {
    font-weight: bold;
    a {
      color: var(--bluelinklight);
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
  button {
    margin: 0;
  }
  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }
`;

export default EmailConfirmationBanner;
