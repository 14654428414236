import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import ProductCard from "src/components/ImagineThis/ProductCard";
import { Row, Col, Spinner, ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useErs } from "src/context/ers/ers.provider";


const fetchInterval = 150_000; // Poll every 2.5mins
const updateFrequency = 200; // Update progress animation every 200ms

const InventoryGrid = ({ tv = false }) => {
  const { t } = useTranslation();
  const { data, fetchData } = useErs(); // Fetch API data
  const [loading, setLoading] = useState(true); // Spinner for initial load
  const [polling, setPolling] = useState(false); // Spinner for polling
  const [error, setError] = useState(null); // Error state
  const [lastFetched, setLastFetched] = useState(null); // Store last fetched time
  const [progress, setProgress] = useState(100); // Progress for the next fetch

  useEffect(() => {
    let isFirstLoad = true;

    const fetchInventory = async () => {
      if (isFirstLoad) {
        setLoading(true);
      } else {
        setPolling(true);
      }

      try {
        await fetchData(`imagine-this-2025-prize-counter`);
        setLoading(false);
        setError(null);

        // Format time as HH:MM:SS
        const now = new Date();
        const formattedTime = now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" });
        setLastFetched(formattedTime);
        setProgress(100); // Reset progress bar
      } catch (err) {
        setLoading(false);
        setError(err.message || t("error.somethingWentWrong"));
        console.error("Error fetching entertainment data:", err);
      }

      setPolling(false);
      isFirstLoad = false;
    };

    fetchInventory(); // Initial fetch

    const interval = setInterval(fetchInventory, fetchInterval); // Poll every 10 seconds

    const progressStep = 100 / (fetchInterval / updateFrequency); // Step size per interval
    const progressInterval = setInterval(() => {
      setProgress((prev) => Math.max(prev - progressStep, 0)); // Smooth decrement
    }, updateFrequency);

    return () => {
      clearInterval(interval); // Cleanup fetch interval
      clearInterval(progressInterval); // Cleanup progress interval
    };
  }, [fetchData]);

  return (

    <Div tv={tv}>
      <div className="grid-content">
        {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t("loading")}</span>
          </Spinner>
        </div>
        ) : error ? (
        <div className="text-center text-danger">
            <p>{t("error.somethingWentWrong")}: {error}</p>
        </div>
        ) : (
        <Row xs={2} sm={3} md={4} lg={4}  className="g-1">
          {Object.values(data[`imagine-this-2025-prize-counter`])?.map((item, index) => (
            <Col key={item?.nameen || index} className={tv ? "tv" : ""}>
                <ProductCard item={item} />
            </Col>
          ))}
        </Row>
        )}
        {/* Last Fetched Time */}
        <FetchedTime>
        <ProgressBarContainer>
            <StyledProgressBar now={polling ? 0 : progress} variant="success" />
        </ProgressBarContainer>
        {polling ? (
            <Spinner animation="border" size="sm" role="status">
            <span className="visually-hidden">{t("loading")}</span>
            </Spinner>
        ) : lastFetched ? (
            `Updated: ${lastFetched}`
        ) : (
            t("fetching")
        )}
        </FetchedTime>
      </div>
    </Div>
  );
};

const Div = styled.div`
  .grid-content {
    max-width: 1080px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .row {
      margin: auto;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      // Control the col height if tv prop is true
      .col.tv {
        height: calc(100vh / 8.2);
      }
    }
  }
`;

const ProgressBarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  .progress {
    height: 2px;
  }
`;

const StyledProgressBar = styled(ProgressBar)`
  transition: width 0.1s linear; /* Smooth animation */
`;

const FetchedTime = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: var(--white);
  padding: 5px 10px;
  font-size: 12px;
  z-index: 999;
  width: 150px;
  opacity: 0.8;
  text-align: center;
  .spinner-border-sm {
    opacity: 0.5;
    height: 0.8rem;
    width: 0.8rem;
  }
`;

export default InventoryGrid;
