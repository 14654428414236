import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PropTypes from "prop-types";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";
import { formatAmount } from "src/utils/formatters";

const WinnerPic = ({ imageSrc, amount, language }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ResponsiveImage src={`egd/jackpots/winners/${imageSrc}`} alt={t("egd.jackpot-winners-title.image-alt")} />
      <Amount>{formatAmount(amount, language)}</Amount>
    </Container>
  );
};

WinnerPic.propTypes = {
  imageSrc: PropTypes.string.isRequired, // Image path
  amount: PropTypes.string.isRequired, // Amount won
  altText: PropTypes.string, // Image alt text
  language: PropTypes.string.isRequired, // Language
};

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  /* Maintain aspect ratio */
  &:before {
    content: "";
    display: block;
    padding-top: 67.4%; /* 1079 / 1600 aspect ratio */
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Ensures it fills the container */
    object-fit: cover; /* Prevents distortion */
  }
`;


const Amount = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 1.2rem;
  white-space: nowrap;
  text-align: center;
  font-weight: 700;
  color: var(--white);
  background: -webkit-linear-gradient(0deg, #c68a4f 30%, #fcc578 40%, #c68a4f 70%, #f8c886 120%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.3rem;
  font-size: clamp(2rem, 17vw, 15rem);
  line-height: clamp(2rem, 17vw, 15rem);
  @media (min-width: 768px) {
    font-size: clamp(2rem, 8vw, 6rem);
    line-height: clamp(2rem, 8vw, 6rem);
  }
`;

export default WinnerPic;
