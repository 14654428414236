import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const BannerTitle = ({
  title,
  highlight,
  as: Tag = "h1",
  dark = true,
  className,
  switchOrder = false,
  titleCustomCss = {},
  highlightCustomCss = {}
}) => {
  return (
    <Section className={`${className || ""}`}>
      <Tag>
        <FlexWrapper switchOrder={switchOrder}>
          <TitleText className="banner-title-text" dark={dark} titleCustomCss={titleCustomCss}>
            {title.replace(highlight, "")}
          </TitleText>
          {highlight && <HighlightText className="banner-highlight-text" highlightCustomCss={highlightCustomCss}>{highlight}</HighlightText>}
        </FlexWrapper>
      </Tag>
    </Section>
  );
};

BannerTitle.propTypes = {
  title: PropTypes.string.isRequired, // Main title text
  highlight: PropTypes.string, // Optional highlighted word
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]), // HTML tag for title
  dark: PropTypes.bool, // Dark mode flag
  className: PropTypes.string, // Custom class name
  switchOrder: PropTypes.bool, // Switches order of title and highlight
  titleCustomCss: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom styles for title
  highlightCustomCss: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom styles for highlighted word
};

const Section = styled.div`
  width: 100%;
  padding: 1rem 0;
`;

const FlexWrapper = styled.span`
    display: flex;
    // If switchOrder is true, reverse the order
    flex-direction: ${(props) => (props.switchOrder ? "column-reverse" : "column")};
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 1rem 0 0;
  `;

const TitleText = styled.div`
  // if dark mode is enabled, set the background to white and the text to black
  background: ${(props) => (props.dark ? "white" : "black")};
  color: ${(props) => (props.dark ? "black" : "white")};
  font-weight: 700;
  padding: 0.25rem 20px;
  text-transform: uppercase;
  width: 100%;
  ${(props) => props.titleCustomCss || ""}
  font-size: 1rem;
  @media (min-width: 768px) {
    font-size: 1.5rem;
    padding: 0.5rem;
  }
`;

const HighlightText = styled.span`
  /* Adjust based on char count in parent component. Hook onto className */
  font-size: ${({ children }) => `clamp(1rem, ${(100 / children.length) + 2}vw, 6rem)`};
  font-weight: 900;
  text-transform: uppercase;
  background: linear-gradient(to right, #650000, #d11a1a, #650000); /* Red gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Makes gradient visible */
  -webkit-text-stroke: 2px white; /* White outline */
  display: inline-block;
  ${(props) => props.highlightCustomCss || ""}
`;


export default BannerTitle;
