import React, { useEffect } from "react";
import styled from "styled-components/macro";
import Hero from "src/components/Hero/Hero";
import InventoryGrid from "src/components/ImagineThis/InventoryGrid";


const ThursdayGiftInventory = () => {

  return (
    <>
      <Div>
        <Hero
          arrowBounce="true"
          bgImage="ers/imagine-this-2025-prize-counter/LP-Thursday-Gift-Inventory-Banner.jpg"
          buttonLink=""
          buttonText=""
          descriptionBgColor="none"
        />
        <InventoryGrid />
      </Div>
    </>
  );
};


const Div = styled.div`
  background: var(--black);
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--white);

  .hero_container {
    min-height: 500px;
    margin-bottom: 20px;
  }
  .hero_text_container {
    display: none;
  }
`;

export default ThursdayGiftInventory;
